import React, { useEffect, useState } from "react";
import graphic from "../images/mobile_notification_graphic.png";
import notification_bell from "../images/notification_bell.png";
import "../components/SubscribeSection.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import { analytics, db } from "../config/firebaseConfig.js";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { getGoogleAnalyticsClientId } from "firebase/analytics";
// Importing toastify module
import { ToastContainer, toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

/**
 * @mode -> 0: Whatsapp, 1: SMS, 2: Call
 * @status -> -1: Unsubscribed (Off), 0: All Alerts Active, 1: Launch Updates Only, 2: Scam Alerts Only, 3: (1+2)
 */
class Subscriber {
  constructor(country, whatsapp, mode, status, createdAt, updatedAt) {
    this.country = country;
    this.whatsapp = whatsapp;
    this.mode = mode;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  toString() {
    return (
      "+" +
      this.country +
      this.whatsapp +
      " (" +
      (this.mode == 0 ? "WHATSAPP" : this.mode == 1 ? "SMS" : "CALL") +
      ")"
    );
  }
}

// Firestore data converter
const subscriberConverter = {
  toFirestore: (subscriber) => {
    return {
      country: subscriber.country,
      whatsapp: subscriber.whatsapp,
      mode: subscriber.mode,
      status: subscriber.status,
      createdAt: subscriber.createdAt,
      updatedAt: subscriber.updatedAt,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Subscriber(
      data.country,
      data.whatsapp,
      data.mode,
      data.status,
      data.createdAt,
      data.updatedAt
    );
  },
};

export default function SubscribeSection() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [subscriberNum, setSubscriberNum] = useState(-1);

  const submitSubscriberNum = async () => {
    const timestamp = new Date().toISOString();
    console.log("AKASH : number is - ", subscriberNum);
    if (subscriberNum.toString().length == 10 && subscriberNum > 4999999999) {
      console.log("Dev: Preliminary validation passed: ", subscriberNum);
      const docRef = await addDoc(
        collection(db, "cities").withConverter(subscriberConverter),
        new Subscriber(91, subscriberNum, 0, 0, timestamp, timestamp)
      );
      console.log("Akash: Document written with ID: ", docRef.id);
      toast(subscriberNum + " Subscribed ✅", {
        position: "bottom-center",
      });
    } else {
      console.log("Dev: INVALID input is - ", subscriberNum);
      toast(subscriberNum + " is Invalid. ⚠️", {
        position: "bottom-center",
      });
    }
  };

  const log = (input) => {
    const value = input.target.value;
    console.log("AKASH : subscriberNum before set is - ", subscriberNum);
    console.log("AKASH : input is - ", value);
    //This is an async update hence need to rely on useEffect for enabling theb button
    setSubscriberNum(value);
  };

  const updateButton = () => {
    if (subscriberNum.toString().length == 10 && subscriberNum > 4999999999) {
      // Enable subscribe button
      setIsDisabled(false);
    } else {
      // Disable subscribe button
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    console.log("Do something after counter has changed", subscriberNum);
    console.log("AKASH : subscriberNum after set is - ", subscriberNum);
    updateButton();
  }, [subscriberNum]);

  return (
    <>
      <ToastContainer />
      <section
        className=""
        style={{
          backgroundColor: "#E62A46",
          paddingTop: "1rem",
        }}
      >
        <div className="row col-10 mx-auto">
          <div className="col-sm-12 col-lg-10">
            <div className="section-main d-flex flex-row roboto-black mt-2">
              <img
                src={notification_bell}
                style={{ height: 40 }}
                className="section-img img-fluid sm:w-screen lg:w-screen"
                alt="i"
              />
              <span style={{ paddingLeft: "0.5rem" }}></span>
              <h2 className="section-header" style={{ color: "white" }}>
                <strong>Game on soon!!!</strong>
              </h2>{" "}
              <br></br>
            </div>
            <p
              className="section-content"
              style={{ color: "white", fontWeight: "500" }}
            >
              Get notified when the game is live, we'll send your invite code
              over
              <br></br>WhatsApp so that you can experience it early as soon as
              it's launched
            </p>
            <form className="d-flex form">
              <div className="d-flex form-group mb-2">
                {/* <label for="inputPassword2"  className="sr-only">Password</label> */}
                <input
                  className="form-control"
                  type="tel"
                  id="phone"
                  placeholder="Enter your WhatsApp number"
                  prefix="+91"
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      submitSubscriberNum();
                    }
                  }}
                  // value={subscriberNum}
                  // onChange={(e) => {
                  //   setSubscriberNum(e.target.value);

                  //   console.log("AKASH : subscriberNum after set is - ", {
                  //     subscriberNum,
                  //   });
                  //   const a = new Subscriber(
                  //     91,
                  //     e.target.value,
                  //     0,
                  //     0,
                  //     67890,
                  //     67890
                  //   );
                  //   console.log("AKASH : a after set is - ", { a });
                  // }}
                  onChange={(e) => log(e)}
                />
                <div>
                  <button
                    type="button"
                    // type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#263156",
                      color: "white",
                      fontWeight: "500",
                    }}
                    onClick={submitSubscriberNum}
                    disabled={isDisabled}
                  >
                    SUBSCRIBE
                  </button>
                  {/* <ToastContainer /> */}
                  <IoInformationCircleOutline style={{ color: "white" }} />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-6 col-lg-2 graphic">
            <img
              src={graphic}
              className="img-fluid sm:w-screen lg:w-screen mt-2"
              alt="home-img"
            />
          </div>
        </div>
      </section>
    </>
  );
}
