import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import firebaseui from "firebaseui";

// const firebaseConfig = {
//   apiKey: "apiKey",
//   authDomain: "authDomain",
//   projectId: "projectId",
//   storageBucket: "storageBucket",
//   messagingSenderId: "messagingSenderId",
//   appId: "appId",
//   measurementId: "measurementId",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBwQtZnrnwczbpizQMSjUZwSmxJEZTZaw4",
  authDomain: "fbs-fightbackscams.firebaseapp.com",
  projectId: "fbs-fightbackscams",
  storageBucket: "fbs-fightbackscams.appspot.com",
  messagingSenderId: "646150815450",
  appId: "1:646150815450:web:6b9073c0f04fe1b1a86fe3",
  measurementId: "G-E06KW7W2K4",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true; // Disable for testing only
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
// const ui = new firebaseui.auth.AuthUI(auth);

export { app, auth, analytics, db, storage };
