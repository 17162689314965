import React, { useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap"; // Or any UI library

const UploadProgressAlert = ({ show, progress, message, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="text-center">
        <div className="mb-3">
          {/* Circular Progress Bar */}
          <div
            style={{
              width: "60px",
              height: "60px",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <svg width="60" height="60">
              <circle
                cx="30"
                cy="30"
                r="28"
                stroke="#e6e6e6"
                strokeWidth="4"
                fill="none"
              />
              <circle
                cx="30"
                cy="30"
                r="28"
                stroke="#3b82f6"
                strokeWidth="4"
                fill="none"
                strokeDasharray="176" // Circumference of the circle
                strokeDashoffset={176 - (progress / 100) * 176}
                style={{ transition: "stroke-dashoffset 0.2s linear" }}
              />
            </svg>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {`${progress}%`}
            </div>
          </div>
        </div>
        {/* Linear Progress Bar */}
        <ProgressBar now={progress} className="mb-2" />
        <p className="mb-0">{message}</p>
      </Modal.Body>
    </Modal>
  );
};

export default UploadProgressAlert;
