import { React, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import "../components/Fonts.css";
import "../components/ReportScam.css";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
// import { app as firebaseApp } from "../config/firebaseConfig.js";
import { auth, db, storage } from "../config/firebaseConfig.js";
import { countryCodes } from "../config/countryCodeList.js";
import UploadProgressAlert from "./uploadProgressAlert.js";
import LoginPopup from "./LoginPopup.js";

function ReportScam() {
  const [showAlert, setShowAlert] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fields, setFields] = useState([""]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedScamTypes, setSelectedScamTypes] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [selectedVictimRadio, setSelectedVictimRadio] = useState(null);
  const [isFinancialLoss, setIsFinancialLoss] = useState(true);
  const [fileUrls, setFileUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [validfiles, setValidFiles] = useState([]);
  const [proofInfoMessage, setProofInfoMessage] = useState("");
  const [proofErrorMessage, setProofErrorMessage] = useState([]);
  // const [countryCode, setCountryCode] = useState("");

  //For Login
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLoginPopup, setShowLoginPopup] = useState(true);
  //const navigate = useNavigate();

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false);
    // Additional logic after login can go here
  };

  const handleReportScamClick = () => {
    setShowLoginPopup(true);
  };

  //default country code: 🇮🇳 +91 - India
  const [contactInfo, setContactInfo] = useState({
    name: "",
    whatsappCountryCode: "+91",
    whatsappNumber: "",
    phoneCountryCode: "+91",
    phoneNumber: "",
    email: "",
    sameAsWhatsApp: true,
  });

  const [contactInfoScammer, setContactInfoScammer] = useState({
    name: "",
    whatsappNumber: "",
    phoneNumber: "",
    email: "",
    sameAsWhatsApp: true,
    phoneCountryCode: "+91",
    whatsappCountryCode: "+91",
  });

  // Handle input changes
  const handleInputChangePI = (field, value) => {
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [field]: value,
      // If checkbox is checked, update phone number with WhatsApp number
      ...(field === "phoneNumber" &&
        prevInfo.sameAsWhatsApp && {
          whatsappNumber: value,
          whatsappCountryCode: prevInfo.phoneCountryCode,
        }),
    }));
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      sameAsWhatsApp: isChecked,
      whatsappNumber: isChecked
        ? prevInfo.phoneNumber
        : prevInfo.whatsappNumber,
      whatsappCountryCode: isChecked
        ? prevInfo.phoneCountryCode
        : prevInfo.whatsappCountryCode,
    }));
  };

  const handleVictimRadioChange = (event) => {
    const victimMap = {
      option1: "self",
      option2: "family",
      option3: "relative",
      option4: "friend",
    };
    setSelectedVictimRadio(victimMap[event.target.value]);
  };

  // Format the current date as needed
  useEffect(() => {
    const today = new Date();

    // Format the date in "YYYY-MM-DD" format for input
    //const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(new Date().toLocaleDateString("en-CA"));

    // Format the date in "20 October 2024" format for display
    // const formattedDisplayDate = today.toLocaleDateString("en-GB", {
    //   day: "numeric",
    //   month: "long",
    //   year: "numeric",
    // });
    // setDisplayDate(formattedDisplayDate);
  }, []);

  // List of platforms to display as checkboxes-
  const platforms = [
    { id: "whatsapp", label: "WhatsApp" },
    { id: "telegram", label: "Telegram" },
    { id: "facebook", label: "Facebook" },
    { id: "instagram", label: "Instagram" },
    { id: "email", label: "Email" },
    { id: "phonecall", label: "Phone Call" },
    { id: "apk", label: "Android App (Apk)" },
    { id: "exe", label: "Windows exe" },
    { id: "dmg", label: "MacOS dmg" },
    { id: "ipa", label: "iOS App(ipa)" },
    { id: "other", label: "Other" },
  ];

  // List of scam types to display as checkboxes
  const scamTypes = [
    { id: "work_from_home", label: "Work from home Jobs" },
    { id: "identity_theft", label: "Identity theft" },
    { id: "courier_scam", label: "Courier scam" },
    { id: "suspicious_link", label: "Suspicious link" },
    { id: "otp_scam", label: "OTP scam" },
    { id: "phone_call", label: "Phone call" },
    { id: "ecommerce", label: "Fake Amazon Payment Request" },
    { id: "other", label: "Other" },
  ];

  // Handle checkbox changes
  const handleScamCheckboxChange = (scamId) => {
    setSelectedScamTypes(
      (prevSelected) =>
        prevSelected.includes(scamId)
          ? prevSelected.filter((id) => id !== scamId) // Deselect if already selected
          : [...prevSelected, scamId] // Add if not selected
    );
  };

  // Handle checkbox changes
  const handlePlatformCheckboxChange = (platformId) => {
    setSelectedPlatforms(
      (prevSelected) =>
        prevSelected.includes(platformId)
          ? prevSelected.filter((id) => id !== platformId) // Deselect if already selected
          : [...prevSelected, platformId] // Add if not selected
    );
  };

  const handlePhoneInputChange = (e) => {
    let phone = e.target.value;
    phone = phone.replace(/[^0-9]/g, "").replace(/^0+/, ""); // Clean phone input
    if (phone.length <= 10) {
      handleInputChangePI("phoneNumber", phone);
    }
  };

  // Handle WhatsApp number input and clean the input
  const handleWhatsAppInputChange = (e) => {
    let whatsapp = e.target.value;
    whatsapp = whatsapp.replace(/[^0-9]/g, "").replace(/^0+/, ""); // Clean WhatsApp input
    if (whatsapp.length <= 10) {
      handleInputChangePI("whatsappNumber", whatsapp);
    }
  };

  const handleScammerPhoneCountryCodeChange = (e) => {
    setContactInfoScammer((prev) => ({
      ...prev,
      phoneCountryCode: e.target.value,
    }));
  };

  const handlePhoneCountryCodeChange = (e) => {
    setContactInfo((prev) => ({
      ...prev,
      phoneCountryCode: e.target.value,
      // If checkbox is checked, sync WhatsApp country code
      ...(prev.sameAsWhatsApp && { whatsappCountryCode: e.target.value }),
    }));
  };

  // Handle country code change for WhatsApp number
  const handleWhatsAppCountryCodeChange = (e) => {
    setContactInfo((prev) => ({
      ...prev,
      whatsappCountryCode: e.target.value,
    }));
  };

  // Handler for input change in Scammers Other Contact Info
  const handleInputChangeOtherContactInfo = (index, event) => {
    const newValue = event.target.value;

    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = newValue;

      // Add a new empty field if typing in the last field, total fields < 7, and value is not empty
      if (
        index === updatedFields.length - 1 &&
        newValue !== "" &&
        updatedFields.length < 7
      ) {
        updatedFields.push("");
      }

      return updatedFields;
    });

    console.log("Editing Fields:", fields); // This will log the previous state (not immediately updated)
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 10) {
      setProofErrorMessage("You can upload a maximum of 10 files.");
      return;
    }
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 16 * 1024 * 1024
    );
    setFiles(selectedFiles);
    setValidFiles(validFiles);
    if (validFiles.length !== selectedFiles.length) {
      setProofErrorMessage("Each file must be 16MB or smaller.");
      return;
    }
    setProofErrorMessage("");
  };

  const handleFormSubmit = async () => {
    try {
      setShowAlert(true); // Show the progress alert
      // Step 1: Filter out 'sameAsWhatsApp' from contactInfo
      const { sameAsWhatsApp, ...filteredContactInfo } = contactInfo;

      // Step 2: Construct the form data
      const formData = {
        ...filteredContactInfo,
        scammersOtherContacts: fields.filter((field) => field.trim() !== ""), // Include non-empty fields
        victim: selectedVictimRadio,
        isFinancialLoss,
        selectedPlatforms,
        selectedScamTypes,
        proofUrls: fileUrls,
        date: currentDate,
      };

      // Step 3: Submit the initial document to Firestore
      const docRef = await addDoc(
        // collection(db, "fbs", "member", "scam_reports"),
        collection(db, "scam_reports"),
        formData
      );

      if (files.length > 0) {
        const uploadedFileUrls = await Promise.all(
          files.map((file, index) => {
            const storageRef = ref(
              storage,
              `proofs/${docRef.id}_${index}_${file.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  setUploadProgress(progress); // Update progress
                },
                (error) => reject(error),
                async () => {
                  const downloadURL = await getDownloadURL(
                    uploadTask.snapshot.ref
                  );
                  resolve(downloadURL);
                }
              );
            });
          })
        );

        await updateDoc(docRef, { proofs: uploadedFileUrls });
      }

      // Reset the form fields
      setFields([""]);
      setSelectedVictimRadio(null);
      setIsFinancialLoss(true);
      setContactInfo({
        name: "",
        whatsappCountryCode: "+91",
        whatsappNumber: "",
        phoneCountryCode: "+91",
        phoneNumber: "",
        email: "",
        sameAsWhatsApp: true,
      });
      setFiles([]);
      setFileUrls([]);

      // alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      // alert("Failed to submit the form. Please try again.");
    } finally {
      setShowAlert(false); // Hide the progress alert
      setUploadProgress(0); // Reset progress
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  return (
    <>
      <button onClick={handleReportScamClick}>Report Scam</button>
      {showLoginPopup && (
        <LoginPopup show={showLoginPopup} handleClose={handleLoginPopupClose} />
      )}
      {/* <div id="recaptcha-container"></div> */}
      {/* Upload Progress Alert */}
      <UploadProgressAlert
        show={showAlert}
        progress={uploadProgress}
        message={
          files.length > 0
            ? "Uploading files... Please wait"
            : "Submitting report... Please wait"
        }
        handleClose={() => setShowAlert(false)}
      />
      <section className="" style={{ backgroundColor: "#EAEDF4" }}>
        <div className="pt-3 pb-2" style={{ backgroundColor: "white" }}>
          <div className="col-10 mx-auto">
            <h3
              className="roboto-regular"
              style={{
                fontcolor: "#222626",
                fontWeight: "bold",
              }}
            >
              Please fill your information
            </h3>
            <p className="roboto-regular">
              Have you or someone you know been a victim of a scam? Please share
              any details about fraudulent messages or phone calls here
            </p>
          </div>
        </div>
        <div className="col-10 mx-auto mt-4">
          {/* Question 01: Name */}
          <div className="form-q-01 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Name</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group className="">
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={contactInfo.name}
                  onChange={(e) => handleInputChangePI("name", e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          {/* Phone Number Input with Country Code */}
          <div className="form-q-02 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Phone Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12 d-flex flex-column">
              <Form.Group className="d-flex" style={{ alignItems: "center" }}>
                {/* Country Code Dropdown */}
                <Form.Select
                  style={{
                    width: "30%",
                    marginRight: "0.5rem",
                    color: "#A1A1A1",
                    fontSize: "small",
                  }}
                  value={contactInfo.phoneCountryCode}
                  // value={"🇮🇳 +91 - India"}
                  onChange={handlePhoneCountryCodeChange}
                >
                  {countryCodes.map((code) => (
                    <option
                      key={`${code.dial_code}-${code.code}`}
                      value={code.dial_code}
                    >
                      {contactInfo.whatsappCountryCode === code.dial_code
                        ? `${code.flag} ${code.dial_code}`
                        : `${code.flag} ${code.dial_code} - ${code.name}`}
                    </option>
                  ))}
                </Form.Select>

                {/* Phone Number Input */}
                <Form.Control
                  type="text"
                  placeholder="Enter your phone number"
                  value={contactInfo.phoneNumber}
                  maxLength={10}
                  onChange={handlePhoneInputChange}
                  style={{ width: "70%", color: "#A1A1A1", fontSize: "small" }}
                />
              </Form.Group>
            </div>
          </div>

          {/* WhatsApp Number Input with Country Code */}
          <div className="form-q-03 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your WhatsApp Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12 flex-column">
              <Form.Group className="d-flex" style={{ alignItems: "center" }}>
                {/* Country Code Dropdown for WhatsApp */}
                <Form.Select
                  style={{
                    width: "30%",
                    marginRight: "0.5rem",
                    color: "#A1A1A1",
                    fontSize: "small",
                  }}
                  value={contactInfo.whatsappCountryCode}
                  onChange={handleWhatsAppCountryCodeChange}
                  disabled={contactInfo.sameAsWhatsApp}
                >
                  {countryCodes.map((code) => (
                    <option
                      key={`${code.dial_code}-${code.code}`}
                      value={code.dial_code}
                    >
                      {contactInfo.whatsappCountryCode === code.dial_code
                        ? `${code.flag} ${code.dial_code}`
                        : `${code.flag} ${code.dial_code} - ${code.name}`}
                    </option>
                  ))}
                </Form.Select>

                {/* WhatsApp Number Input */}
                <Form.Control
                  type="text"
                  placeholder="Enter your WhatsApp number"
                  value={contactInfo.whatsappNumber}
                  maxLength={10}
                  onChange={handleWhatsAppInputChange}
                  disabled={contactInfo.sameAsWhatsApp} // Disable if checkbox is checked
                  style={{ width: "70%", color: "#A1A1A1", fontSize: "small" }}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="Same as Phone Number"
                  id="sameAsWhatsAppCheckbox"
                  checked={contactInfo.sameAsWhatsApp}
                  onChange={handleCheckboxChange}
                  style={{ display: "block", fontSize: "small" }}
                />
              </Form.Group>
            </div>
          </div>

          {/* Question 04: Email */}
          <div className="form-q-04 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Email</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={contactInfo.email}
                  onChange={(e) => handleInputChangePI("email", e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          {/* question 1 */}
          <div className="form-q-1 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12" style={{ marginLeft: "" }}>
              <h6>Is this a financial fraud scam?</h6>
              <p style={{ fontSize: "smaller" }}>
                (Does it involve direct loss of money)
              </p>
            </div>
            <div className="col-lg-7 col-sm-12" style={{ marginLeft: "" }}>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} style={{ fontSize: "medium" }}>
                    <Form.Check
                      // inline
                      label="Yes, It is a case of money loss"
                      name="group1"
                      type={type}
                      id={`inline-${type}-10`}
                    />
                    <Form.Check
                      // inline
                      label="No, It is not a case of money loss"
                      name="group1"
                      type={type}
                      id={`inline-${type}-11`}
                    />
                  </div>
                ))}
              </Form>
            </div>
          </div>

          {/* question 2 */}
          <div className="form-q-2 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12" style={{ marginLeft: "" }}>
              <h6>Are you the direct victim of the fraud/scam?</h6>
            </div>
            <div className="col-lg-7 col-sm-12" style={{ marginLeft: "" }}>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}-00`} style={{ fontSize: "medium" }}>
                    <Form.Check
                      // inline
                      label="Yes, I'm reporting for myself"
                      name="group1"
                      type={type}
                      id={`inline-${type}-01`}
                      value="option1"
                      onChange={handleVictimRadioChange}
                    />
                    <Form.Check
                      // inline
                      label="No, I'm reporting for an immediate family member"
                      name="group1"
                      type={type}
                      id={`inline-${type}-02`}
                      value="option2"
                      onChange={handleVictimRadioChange}
                    />
                    <Form.Check
                      // inline
                      label="No, I'm reporting for a relative"
                      name="group1"
                      type={type}
                      id={`inline-${type}-03`}
                      value="option3"
                      onChange={handleVictimRadioChange}
                    />
                    <Form.Check
                      // inline
                      label="No, I'm reporting for a friend"
                      name="group1"
                      type={type}
                      id={`inline-${type}-04`}
                      value="option4"
                      onChange={handleVictimRadioChange}
                    />
                  </div>
                ))}
              </Form>
            </div>
          </div>

          {/* question 3 */}
          <div className="form-q-3 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12" style={{ marginLeft: "" }}>
              <h6>Date of Scam</h6>
            </div>
            <div className="col-lg-7 col-sm-6" style={{}}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    style={{ color: "#A1A1A1", fontSize: "small" }}
                    type="date"
                    max={new Date().toLocaleDateString("en-CA")} // Disable dates after today
                    value={currentDate} // Set default date value
                    onChange={(e) => setCurrentDate(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>

          {/* question 4 */}
          <div className="form-q-4 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Total amount lost in scam (in ₹)</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form>
                <Form.Group>
                  <Form.Control
                    type="text" // Use text to handle validation manually
                    maxLength={15} // Optional: restrict maximum length
                    placeholder="Amount in INR (₹)"
                    onChange={(e) => {
                      let value = e.target.value;

                      // Allow only digits and a single decimal
                      const cleanedValue = value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                      const parts = cleanedValue.split(".");

                      // Ensure only one decimal point is allowed
                      if (parts.length > 2) {
                        value = `${parts[0]}.${parts[1]}`; // Keep only the first decimal point
                      } else if (parts.length === 2) {
                        // Limit to 2 digits after the decimal point
                        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                      }

                      // Edge case: Prevent values starting with just "."
                      if (value.startsWith(".")) {
                        value = `0${value}`;
                      }

                      e.target.value = value; // Update the input value
                    }}
                    onBlur={(e) => {
                      // Edge case: Prevent just "." or empty input
                      if (e.target.value === "." || e.target.value === "") {
                        e.target.value = "";
                      }
                    }}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>

          {/* Phone Number Input with Country Code */}
          <div className="form-q-02 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammers Phone Number</h6>
              <p style={{ fontSize: "smaller" }}>
                (Call/SMS/Whatsapp/Telegram)
              </p>
            </div>
            <div className="col-lg-7 col-sm-12 d-flex flex-column">
              <Form.Group className="d-flex" style={{ alignItems: "center" }}>
                {/* Country Code Dropdown */}
                <Form.Select
                  style={{
                    width: "30%",
                    marginRight: "0.5rem",
                    color: "#A1A1A1",
                    fontSize: "small",
                  }}
                  value={contactInfoScammer.phoneCountryCode}
                  onChange={handleScammerPhoneCountryCodeChange}
                >
                  {countryCodes.map((code) => (
                    <option
                      key={`${code.dial_code}-${code.code}`}
                      value={code.dial_code}
                    >
                      {contactInfo.whatsappCountryCode === code.dial_code
                        ? `${code.flag} ${code.dial_code}`
                        : `${code.flag} ${code.dial_code} - ${code.name}`}
                    </option>
                  ))}
                </Form.Select>

                {/* Phone Number Input */}
                <Form.Control
                  type="text"
                  placeholder="Enter Scammers phone number"
                  value={contactInfo.phoneNumber}
                  maxLength={10}
                  onChange={handlePhoneInputChange}
                  style={{ width: "70%", color: "#A1A1A1", fontSize: "small" }}
                />
              </Form.Group>
            </div>
          </div>

          {/* question 5 */}
          <div className="form-q-5 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammers Other Contact Info</h6>
              <p style={{ fontSize: "smaller" }}>
                Provide all details like Names, Numbers, Emails, Handles,
                Website or any other relevant information.
              </p>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form>
                {fields.map((field, index) => (
                  <Form.Group key={index} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Name, Number, Email, Handle, Website etc."
                      value={field}
                      maxLength={64} // Restrict input to a maximum of 64 characters
                      onChange={(e) =>
                        handleInputChangeOtherContactInfo(index, e)
                      }
                    />
                  </Form.Group>
                ))}
              </Form>
            </div>
          </div>

          {/* question 6 */}
          <div className="form-q-6 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scam Category</h6>
            </div>

            <div className="col-lg-7 col-sm-12">
              <Form>
                <div className="row">
                  {/* Render checkboxes dynamically */}
                  {scamTypes.map((scamType) => (
                    <div
                      key={scamType.id}
                      className="col-lg-4 col-md-6 col-12 mb-3"
                    >
                      <Form.Check
                        type="checkbox"
                        id={scamType.id}
                        label={scamType.label}
                        checked={selectedScamTypes.includes(scamType.id)}
                        onChange={() => handleScamCheckboxChange(scamType.id)}
                      />
                    </div>
                  ))}
                </div>
              </Form>
            </div>
          </div>

          {/* question 7 */}
          <div className="form-q-7 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Platform(s) used by scammer</h6>
            </div>

            <div className="col-lg-7 col-sm-12">
              <Form>
                <div className="row">
                  {/* Render checkboxes dynamically */}
                  {platforms.map((platform) => (
                    <div
                      key={platform.id}
                      className="col-lg-4 col-md-6 col-12 mb-3"
                    >
                      <Form.Check
                        type="checkbox"
                        id={platform.id}
                        label={platform.label}
                        checked={selectedPlatforms.includes(platform.id)}
                        onChange={() =>
                          handlePlatformCheckboxChange(platform.id)
                        }
                      />
                    </div>
                  ))}
                </div>
              </Form>
            </div>
          </div>

          {/* question 8 */}
          <div className="form-q-8 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12" style={{ marginLeft: "" }}>
              <h6>Scam/Fraud info</h6>
            </div>
            <div className="col-lg-7 col-sm-12" style={{}}>
              <Form>
                <Form.Group className="">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Give details of the scam"
                  />
                </Form.Group>
              </Form>
            </div>
          </div>

          {/* Google Drive link for proofs */}
          <div className="form-q-9 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Upload proofs to Google Drive and share link</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="url"
                  placeholder="Enter Google Drive link"
                  value={contactInfo.gdriveProofLink}
                  onChange={(e) =>
                    handleInputChangePI("gdriveProofLink", e.target.value)
                  }
                />
                <Form.Text className="text-muted">
                  Please ensure access is granted to
                  fightbackscamsindia@gmail.com
                </Form.Text>
              </Form.Group>
            </div>
          </div>

          {/* File Upload */}
          <div className="form-q-10 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Upload image/proof (Max: 10 files, each file ≤ 16MB)</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group controlId="formFileMultiple">
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                {proofErrorMessage && (
                  <p className="text-danger">{proofErrorMessage}</p>
                )}
              </Form.Group>
              {files.length > 0 && (
                <ul>
                  {files.map((file) => (
                    <li key={file.name}>
                      {file.size < 16 * 1024 * 1024 ? (
                        <span style={{ color: "green" }}>
                          {formatFileSize(file.size)} - {file.name}
                        </span>
                      ) : (
                        <span className="text-danger">
                          {formatFileSize(file.size)} - {file.name}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div
          className="button"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <button
            className="btn btn-default roboto-bold "
            style={{
              backgroundColor: "#E62A46",
              color: "white",
              width: "9.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <span style={{ fontWeight: "bold" }} onClick={handleFormSubmit}>
              Submit report
            </span>
          </button>
          {/* //TODO: Cleaup this below code */}
          {/* Display the formatted date below the input */}
          {currentDate && (
            <div style={{ fontSize: "small", color: "#A1A1A1" }}>
              Selected Date: {currentDate}
            </div>
          )}
          {/* Display the selected scam types for demonstration */}
          {selectedScamTypes.length > 0 && (
            <div className="mt-4">
              <h6>Selected Scam Types:</h6>
              <ul>
                {selectedScamTypes.map((scamId) => (
                  <li key={scamId}>
                    {scamTypes.find((scamType) => scamType.id === scamId).label}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* Display the selected platforms for demonstration */}
          {selectedPlatforms.length > 0 && (
            <div className="mt-4">
              <h6>Selected Platforms:</h6>
              <ul>
                {selectedPlatforms.map((platformId) => (
                  <li key={platformId}>
                    {
                      platforms.find((platform) => platform.id === platformId)
                        .label
                    }
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* You can now access the contact fields data here */}
          {fields.length > 0 && (
            <div>
              <h6>Submitted Contact Information:</h6>
              <ul>
                {fields.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ReportScam;
