import React, { useState, useEffect } from "react";
import { auth } from "../config/firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { Modal, ProgressBar } from "react-bootstrap";
import "../components/LoginPopup.css";
import { countryCodes } from "../config/countryCodeList.js";

const LoginPopup = ({ show, handleClose, showCloseButton = true }) => {
  //const auth = getAuth(app);
  // const allowedCountryCodes = //Define this to allow login for India Only
  const [step, setStep] = useState(1); // Step 1: Enter Phone, Step 2: Verify OTP
  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(""); // Default country code
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(59);
  const [showSuccess, setShowSuccess] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  // const [appVerifier, setAppVerifier] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  useEffect(() => {
    if (resendTimer > 0 && step === 2) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer, step]);

  useEffect(() => {
    // Initialize RecaptchaVerifier
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "normal", // or 'normal' for visible recaptcha
          // size: "invisible", // or 'normal' for visible recaptcha
          callback: (response) => {
            console.log("XXX Response: " + response);
            console.log("Recaptcha resolved");
            setRecaptcha(true);
          },
          "expired-callback": () => {
            console.log("Recaptcha expired");
            setRecaptcha(false);
          },
        }
      );

      // Render reCAPTCHA widget
      window.recaptchaVerifier.render().then((widgetId) => {
        console.log("Recaptcha widget rendered with ID:", widgetId);
        window.recaptchaWidgetId = widgetId; // Store widget ID if needed
        console.log("widgetID is: " + widgetId);
        console.log("widgetID is: " + window.recaptchaWidgetId);
      });
      // .render()
      // .then((widgetId) => {
      //   window.recaptchaWidgetId = widgetId;
      // });

      return () => {
        // Cleanup when component unmounts
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        }
      };
    }
  }, []);

  const handleSendOtp = async () => {
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError("Please enter a valid phone number");
      return;
    }
    setError(""); // Clear any previous error
    setStep(2);
    console.log("XXX: " + phoneNumber);
    console.log("XXX: " + countryCode + phoneNumber);

    const appVerifier = window.recaptchaVerifier;
    console.log("XXX ---");
    console.log("XXX ---" + appVerifier);

    try {
      const appVerifier = window.recaptchaVerifier; // Use the existing verifier
      if (!appVerifier) {
        throw new Error("Recaptcha verifier is not initialized.");
      }

      console.log("Sending OTP to:", countryCode + phoneNumber);

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        countryCode + phoneNumber,
        appVerifier
      );
      // OTP sent successfully
      setIsOtpSent(true);
      setResendTimer(59);
      console.log("OTP sent successfully");
      window.confirmationResult = confirmationResult; // Save for verifying OTP later
    } catch (err) {
      setError(err.message || "Failed to send OTP. Try again.");
      console.error("Error sending OTP:", err);
    }
    // await signInWithPhoneNumber(auth, countryCode + phoneNumber, appVerifier)
    //   .then((confirmationResult) => {
    //     // SMS sent. Prompt user to type the code from the message, then sign the
    //     // user in with confirmationResult.confirm(code).
    //     setIsOtpSent(true);
    //     setResendTimer(59);
    //     console.log("OTP sent successfully");
    //     window.confirmationResult = confirmationResult;
    //   })
    //   .catch((err) => {
    //     // Error; SMS not sent
    //     setError(err.message || "Failed to send OTP. Try again.");
    //     console.error("Error sending OTP:", err);
    //   });
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length < 6) {
      setError("Please enter the 6-digit OTP");
      return;
    }
    setError("");

    try {
      const confirmationResult = window.confirmationResult; // Retrieve the confirmation result
      console.log("Trying to confirm otp: ", otp);
      const result = await confirmationResult.confirm(otp);
      console.log("User logged in:", result.user);
      setShowSuccess(true);
      setTimeout(() => {
        handleClose();
        setShowSuccess(false);
      }, 3000);
      //handleClose(); // Close the popup after successful login
    } catch (err) {
      setError("Invalid OTP. Please try again.");
      console.error("Error verifying OTP:", err);
    }
  };

  const handleOtpInputChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Allow only numeric input
    const newOtp = otp.split("");
    newOtp[index] = value;
    setOtp(newOtp.join(""));

    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    } else if (!value && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const titles = {
    1: "WELCOME",
    2: "Phone Verification",
    3: "Phone Number Verified",
  };

  const msgs = {
    1: "To ensure authenticity, please verify your mobile number",
    2: "Enter 6 digit verification code sent to your phone number",
    3: "You will be redirected to the main page in a few moments",
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="text-center">
          <div className="overlay">
            <div className="popup">
              {showCloseButton && (
                <button className="close-btn" onClick={handleClose}>
                  ✕
                </button>
              )}
              <div
                className={
                  showSuccess && step === 3 ? "step step-success" : "step"
                }
              >
                {showSuccess && step === 3 && (
                  <div className="success-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20.285 6.705a1 1 0 0 0-1.415 0L9 16.585l-4.285-4.285a1 1 0 0 0-1.415 1.415l5 5a1 1 0 0 0 1.415 0l11-11a1 1 0 0 0 0-1.415z" />
                    </svg>
                  </div>
                )}
                <h2>{titles[step]}</h2>
                <p>{msgs[step]}</p>
                {step === 1 && (
                  <div className="input-group">
                    <select
                      className="country-code"
                      value={countryCode}
                      onChange={(e) => {
                        setCountryCode(e.target.value);
                      }}
                    >
                      {countryCodes.map((code) => (
                        <option
                          key={`${code.dial_code}-${code.code}`}
                          value={code.dial_code}
                        >
                          {countryCode === code.dial_code
                            ? `${code.flag} ${code.dial_code}`
                            : `${code.flag} ${code.dial_code} - ${code.name}`}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      placeholder="Enter your phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                )}
                {step === 2 && (
                  <div className="otp-inputs">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="text"
                        maxLength="1"
                        value={otp[index] || ""}
                        onChange={(e) => {
                          handleOtpInputChange(index, e.target.value);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                )}
                {error && <p className="error">{error}</p>}

                <div id="recaptcha-container"></div>

                {/* <div id="recaptcha-container"></div> */}
                {step === 1 && (
                  <button
                    className="button"
                    disabled={!recaptcha}
                    onClick={() => {
                      handleSendOtp();
                      //setStep(2);
                    }}
                  >
                    Send OTP
                  </button>
                )}
                {step === 2 && (
                  <>
                    {resendTimer > 0 ? (
                      <p className="resend-code-wait">
                        Resend code in {resendTimer} seconds
                      </p>
                    ) : (
                      <p
                        className="resend-code clickable"
                        onClick={() => {
                          setResendTimer(59);
                          handleSendOtp();
                        }}
                      >
                        Resend code
                      </p>
                    )}
                  </>
                )}
                {step === 2 && (
                  <button className="button" onClick={handleVerifyOtp}>
                    Verify
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginPopup;
