import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./components/Home.js";
import GameBanner from "./components/GameBanner.js";
import TypesOfScams from "./components/TypesOfScams.js";
import LearningCorner from "./components/LearningCorner.js";
import { analytics, db } from "./config/firebaseConfig.js";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { getGoogleAnalyticsClientId } from "firebase/analytics";
import Footer from "./components/Footer.js";
import SubscribeSection from "./components/SubscribeSection.js";
import WarningStrip from "./components/WarningStrip.js";
import Nav_bar from "./components/nav_bar.js";
import ReportScam from "./components/ReportScam.js";
import ReportScam2 from "./components/ReportScam2.js";
import Form from "./components/Form.js";
import ProductDemo from "./components/ProductDemo.js";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <WarningStrip />
        <Nav_bar />
        <Routes>
          <Route exact path="/" element={root} />
          <Route path="/report-scam" element={<ReportScam />} />
          <Route path="/games" element={<ReportScam2 />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}
export default App;

const root = (
  <>
    {console.log("AKASH RUN outer html")}
    <Home />
    <SubscribeSection />
    <GameBanner />
    <ProductDemo/>
    <TypesOfScams />
    <LearningCorner />
  </>
);
