import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import image from "../images/Main_logo.png";
function Nav_bar() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="shadow"
      style={{ backgroundColor: "#071F2C" }}
    >
      <Container>
        <Navbar.Brand
          href="#home"
          className="d-flex"
          style={{ color: "white", fontFamily: "sans-serif" }}
        >
          <img
            alt=""
            src={image}
            width="50px"
            height="50px"
            className="d-inline-block align-top "
          />{" "}
          <div>
            <h4
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                paddingLeft: "1rem",
                marginTop: "0.5rem",
              }}
            >
              {" "}
              FightBackScams{" "}
            </h4>
          </div>
        </Navbar.Brand>
        {/* <Navbar.Toggle style={{backgroundColor:'white'}} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav style={{backgroundColor:'#071F2C'}} className="ms-auto">
            <Nav.Link href="#features" style={{color:'white',paddingRight : '2rem'}}>BLOG</Nav.Link>
            <Nav.Link href="#pricing" style={{color:'white', paddingRight : '2rem'}}>GAME</Nav.Link>
            <Nav.Link href="#pricing" style={{color:'white', paddingRight : '2rem'}}>NEWS</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default Nav_bar;
