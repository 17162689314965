import React from "react";
import banner from "../images/Web-banner.png";
import graphic from "../images/game_banner_mobile.png";
import graphic_bg from "../images/game_banner.png";
import Image from "react-bootstrap/Image";
export default function gameAnnouncementBanner() {
  return (
    <>
      <picture>
        <source media="(max-width: 650px)" srcSet={graphic} />
        <Image src={graphic_bg} fluid />
      </picture>
    </>
  );
}
