import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../components/Home.css";
import "../components/Fonts.css";
import "../components/FormPage.js";
import Form from "react-bootstrap/Form";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    console.log("AKASH RUN - Home Modal open");
    setIsOpen(true);
  };

  const handleClose = () => {
    console.log("AKASH RUN - Home Modal close");
    setIsOpen(false);
  };

  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div
          className="container-fluid"
          style={{ overflowX: "hidden", backgroundColor: "#071F2C" }}
        >
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div
                  data-aos="slide-up"
                  className=" justify-content-center home"
                >
                  <div className="roboto-black">
                    <h1 style={{ color: "white" }}>
                      Is it a Scam? Find out <br></br>{" "}
                    </h1>
                  </div>
                  <div className="center-container">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                      <Form>
                        <Form.Group className="mb-3 mt-2 search">
                          <div className="search-bar-with-icon">
                            <Form.Control
                              type="text"
                              placeholder="Scan Your Message for Scams"
                              className="search-input"
                              style={{ borderRadius: "50px" }}
                            />
                            <i className="bi bi-image search-icon"></i>
                          </div>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <h4
                    className="mt-2 content roboto-regular"
                    style={{ color: "white", fontSize: "1.2rem" }}
                  >
                    Received a suspicious message or call? Enter it above or
                    upload a <br></br> screenshot to verify its authenticity and
                    protect yourself from scams
                  </h4>
                  <div className="roboto-black mt-2">
                    <h1 style={{ color: "white" }}>
                      Caught in a Scam? <br></br>{" "}
                    </h1>
                  </div>
                  <div className="roboto-black mt-3 mb-3">
                    <h2 style={{ color: "white" }}>
                      Take Against against{" "}
                      <span style={{ color: "#E62A46" }}>online scam</span>:
                      We’re here to help ! <br></br>{" "}
                    </h2>
                  </div>
                  <div className="report-content">
                    <h5
                      className="pb-2 mt-2 roboto-regular"
                      style={{ color: "white", fontSize: "0.9rem" }}
                    >
                      Have you or someone close to you fallen victim to online
                      fraud, or received scam messages or calls?
                      <br></br>We understand how stressful it can be. You’re not
                      alone—report your experience here and help take a stand
                      against cybercrime
                    </h5>
                  </div>

                  <div className=" app-button">
                    <Link to="/report-scam">
                      <button
                        className="btn btn-default roboto-bold mr-2 mb-3"
                        style={{
                          backgroundColor: "#E62A46",
                          color: "white",
                          width: "10rem",
                          borderRadius: "2rem",
                        }}
                        //onClick={handleShow}
                      >
                        Report Scam
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
