import React from "react";
import warning_icon from "../images/warning_icon.png";

export default function WarningStrip (){
    return (
        <>
        <section className=""style={{backgroundColor :'#E62A46'}}>
     <div className="row col-10 mx-auto">
        <div className="col-sm-12 col-lg-10">
        <div className="section-main d-flex flex-row roboto-black mt-2">
                    <p className="section-header" style={{ color: "white", fontWeight:'normal' }}>
                      Beware of suspicious messages 
                    </p>
                  </div>
        </div>
     </div>

    </section>
        </>
    );
}