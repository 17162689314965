import { React, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { app as firebaseApp } from "../config/firebaseConfig.js"; // Assuming firebase is initialized in firebase.js
import "../components/Fonts.css";
import "../components/ReportScam.css";

function ReportScam2() {
  const db = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);

  const [fields, setFields] = useState([""]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedScamTypes, setSelectedScamTypes] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [fileUrls, setFileUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [contactInfo, setContactInfo] = useState({
    name: "",
    whatsappNumber: "",
    phoneNumber: "",
    email: "",
    scammerName: "",
    scammerPhoneNumber: "",
    scammerWhatsApp: "",
    scammerTelegram: "",
    googleDriveLink: "",
  });

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleInputChange = (field, value) => {
    setContactInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 10) {
      setErrorMessage("You can upload a maximum of 10 files.");
      return;
    }
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 16 * 1024 * 1024
    );
    if (validFiles.length !== selectedFiles.length) {
      setErrorMessage("Each file must be 16MB or smaller.");
      return;
    }
    setFiles(validFiles);
    setErrorMessage("");
  };

  const handleFormSubmit = async () => {
    if (files.length > 0) {
      const uploadedFileUrls = await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `proofs/${file.name}`);
          await uploadBytes(storageRef, file);
          return await getDownloadURL(storageRef);
        })
      );
      setFileUrls(uploadedFileUrls);
    }

    const formData = {
      ...contactInfo,
      selectedPlatforms,
      selectedScamTypes,
      proofUrls: fileUrls,
      date: currentDate,
    };

    const formDocRef = doc(db, "scamReports", contactInfo.email);
    await setDoc(formDocRef, formData);
    alert("Form submitted successfully!");
  };

  return (
    <>
      <section style={{ backgroundColor: "#EAEDF4" }}>
        <div className="col-10 mx-auto mt-4">
          {/* User's Contact Information */}
          <div className="form-q-1 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Name</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={contactInfo.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-2 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your WhatsApp Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="tel"
                  placeholder="Enter WhatsApp number"
                  pattern="[0-9]*"
                  value={contactInfo.whatsappNumber}
                  onChange={(e) =>
                    handleInputChange("whatsappNumber", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-3 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Phone Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  pattern="[0-9]*"
                  value={contactInfo.phoneNumber}
                  onChange={(e) =>
                    handleInputChange("phoneNumber", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-4 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Your Email</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={contactInfo.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          {/* Scammer's Contact Information */}
          <div className="form-q-5 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammer's Name</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter scammer's name"
                  value={contactInfo.scammerName}
                  onChange={(e) =>
                    handleInputChange("scammerName", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-6 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammer's Phone Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="tel"
                  placeholder="Enter scammer's phone number"
                  pattern="[0-9]*"
                  value={contactInfo.scammerPhoneNumber}
                  onChange={(e) =>
                    handleInputChange("scammerPhoneNumber", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-7 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammer's WhatsApp Number</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="tel"
                  placeholder="Enter scammer's WhatsApp number"
                  pattern="[0-9]*"
                  value={contactInfo.scammerWhatsApp}
                  onChange={(e) =>
                    handleInputChange("scammerWhatsApp", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          <div className="form-q-8 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Scammer's Telegram ID</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter scammer's Telegram ID"
                  value={contactInfo.scammerTelegram}
                  onChange={(e) =>
                    handleInputChange("scammerTelegram", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>

          {/* Google Drive link for proofs */}
          <div className="form-q-9 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Upload proofs to Google Drive and share link</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group>
                <Form.Control
                  type="url"
                  placeholder="Enter Google Drive link"
                  value={contactInfo.googleDriveLink}
                  onChange={(e) =>
                    handleInputChange("googleDriveLink", e.target.value)
                  }
                />
                <Form.Text className="text-muted">
                  Please ensure access is granted to
                  fightbackscamsindia@gmail.com
                </Form.Text>
              </Form.Group>
            </div>
          </div>

          {/* File Upload */}
          <div className="form-q-10 mt-3 d-flex row col-lg-10 mx-auto">
            <div className="col-lg-5 col-sm-12">
              <h6>Upload image/proof (Max: 10 files, each file ≤ 16MB)</h6>
            </div>
            <div className="col-lg-7 col-sm-12">
              <Form.Group controlId="formFileMultiple">
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </Form.Group>
            </div>
          </div>

          {/* Submit Button */}
          <div className="button mt-3 d-flex justify-content-center">
            <button
              className="btn btn-default roboto-bold"
              style={{ backgroundColor: "#E62A46", color: "white" }}
              onClick={handleFormSubmit}
            >
              <span style={{ fontWeight: "bold" }}>Submit report</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReportScam2;
