import React from "react";
import Banner1 from "../images/Blog1_graphic.png";
import Banner2 from "../images/Blog2_graphic.png";
import Banner3 from "../images/Blog3_graphic.png";
import "../components/Fonts.css";
import "../components/LearningCorner.css";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardGroup";
function LearningCorner() {
  return (
    <>
      <section style={{ backgroundColor: "#EAEDF4" }}>
        <div className="container px-5">
          <div>
            <h1 className="fw-bolder pt-4 " style={{ color: "#484646" }}>
              Tips to protect yourself from scammers
            </h1>
          </div>
          <CardDeck className="mt-3 mb-3">
            <Card className="m-2">
              <Card.Img
                className="card-img-top"
                style={{ height: "8rem", width: "8rem" }}
                src={Banner1}
                variant="top"
              />
              <Card.Body>
                <Card.Title>
                  <h4
                    className="card-title roboto-black"
                    style={{ fontWeight: "bold" }}
                  >
                    Never install an apk/ipa/exe file in your phones & laptops
                  </h4>
                </Card.Title>
                <Card.Text className="roboto-regular">
                  If someone calls you & sends you an app file over whatsapp or
                  telegram, just block them! Always check for app reviews even
                  when using trusted App stores like Google play stores
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="m-2">
              <Card.Img
                className="card-img-top"
                style={{ height: "8rem", width: "8rem" }}
                src={Banner2}
                variant="top"
              />
              <Card.Body>
                <Card.Title>
                  <h4
                    className="card-title roboto-black"
                    style={{ fontWeight: "bold" }}
                  >
                    Hear Something too good to be true?
                  </h4>
                </Card.Title>
                <Card.Text className="roboto-regular">
                  The internet can be a fun and useful place, but watch out for
                  sneaky tricks! Never send any money, Always google search for
                  the story scammer is telling and you'll find many similar
                  cases to confirm fraud.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="m-2">
              <Card.Img
                className="card-img-top"
                style={{ height: "8rem", width: "8rem" }}
                src={Banner1}
                variant="top"
              />
              <Card.Body>
                <Card.Title>
                  <h4
                    className="card-title roboto-black"
                    style={{ fontWeight: "bold" }}
                  >
                    Call & SMS
                  </h4>
                </Card.Title>
                <Card.Text className="roboto-regular">
                  Never transfer money over a phone call & do not transfer money
                  to strangers under any circumstances. Always check for
                  comments on callers truecaller profile & search for details of
                  potential scammer on our website. Always Read SMS & understand
                  what it says. If its an OTP then always check the name of the
                  bank or company to verify if you requested this OTP or not.
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </section>
    </>
  );
}

export default LearningCorner;
