import React from "react";
import Card1 from "../images/Map_report.png";
import Card2 from "../images/graph_report.png";
import Banner6 from "../images/Banner 6.png";
import "../components/Fonts.css";
function TypesOfScams() {
  return (
    <>
      <section className="pt-3" style={{ backgroundColor: "#EAEDF4" }}>
        <div className="container px-5 pb-2">
          <div className="">
            <h1 className="fw-bolder mb-0" style={{ color: "#484646" }}>
              Cyber crime can happen to anyone
            </h1>
          </div>
          <div className="card mt-5 mb-3" style={{ maxWidth: "100%" }}>
            <div className="row">
              <div className="col-md-4">
                <img className="card-img-top" src={Card1} />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h1 className="card-title" style={{ color: "#E62A46" }}>
                    22,57,808
                  </h1>
                  <h5 style={{ color: "#071F2C", fontWeight: "bold" }}>
                    Online fraud cases registered in last 2 yrs.<br></br> People
                    have already Lost 4.69 Lakh crores & counting...
                  </h5>
                  <div className="mt-3">
                    <p className="card-text">
                      Financial frauds in India have surged dramatically in
                      recent years, posing a severe threat to the nation's
                      economy and its citizens. A staggering Rs 4.69 lakh crore
                      were lost to bank frauds alone between June 1, 2014, and
                      March 31, 2023, as reported by the Reserve Bank of India.
                      This represents a substantial increase from previous
                      years, with the cumulative impact of various scams,
                      including UPI fraud, bank frauds, and email scams,
                      resulting in a loss exceeding Rs 200 crore in 2023 alone.
                      This alarming trend underscores the escalating
                      sophistication of fraudsters who employ advanced tactics
                      to deceive unsuspecting victims. From phishing attacks and
                      fake investment schemes to identity theft and loan frauds,
                      the modus operandi is evolving rapidly, making it
                      increasingly difficult for people to stay protected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-5 mb-3" style={{ maxWidth: "100%" }}>
            <div className="row g-0">
              <div className="col-md-4">
                <img className="card-img-top" src={Card2} />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h1 className="card-title" style={{ color: "#E62A46" }}>
                    49%
                  </h1>
                  <h5 style={{ color: "#071F2C", fontWeight: "bold" }}>
                    Failed to spot scam messages.
                    <br></br>
                    Middle class suffer an average loss of over ₹30,000
                    <span
                      id="marker"
                      style={{
                        color: "071F2C",
                        backgroundPosition: "top right",
                      }}
                    >
                      *
                    </span>
                  </h5>
                  <div className="mt-3">
                    <p className="card-text">
                      Popular financial frauds in India have become increasingly
                      sophisticated, with Telegram emerging as a favored
                      platform for scammers. Some very common telgram scams:
                      <br></br>• Part time job scams - Fake review in Google
                      Maps or scam websites.
                      <br></br>• "Friend in need" scams
                      <br></br>• Telegram romance scams
                      <br></br>• "Classiscam" schemes (fake classified ad scams)
                      <br></br>• "Pump and dump" crypto channels
                      <br></br>• Investment on Trust Scams & many more ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TypesOfScams;
