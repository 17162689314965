import Ratio from 'react-bootstrap/Ratio';
import Video from "../images/Product_demo.mp4";
import "../components/ProductDemo.css";

function ProductDemo() {
  return (
    <>
    <section  style={{backgroundColor:'#010232'}}>
        <div className='row'>
        <div className ='mt-2 mb-2'style={{ maxWidth:'auto', height: 'auto' }}>
        <Ratio className="autoplay"aspectRatio="16x9">
        <embed src= {Video} />
        </Ratio>
    </div>
        </div>
    </section>


    </>

  );
}

export default ProductDemo;